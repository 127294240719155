import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://c68b31454c315c190581015b3f7b921c@o1044920.ingest.us.sentry.io/4508243554533376",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});